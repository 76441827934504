
import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./Invader.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import InvaderPhoto1 from "../../../../res/Photos site/Invader/invader.jpeg"
import InvaderPhoto2 from "../../../../res/Photo du moment/Photo 2.jpeg"
import InvaderPhoto3 from "../../../../res/Photos site/Invader/invader au MAMO by invader_2_ter.jpg"
import InvaderPhoto4 from "../../../../res/Photos site/Invader/1.webp"
import InvaderPhoto5 from "../../../../res/Photos site/Invader/2.jpg"
import InvaderPhoto6 from "../../../../res/Photos site/Invader/3.jpg"
import InvaderPhoto7 from "../../../../res/Photos site/Photos Oeuvres Disponibles/Invader half phantom.jpeg"
import InvaderPhoto8 from "../../../../res/Photos site/Photos Oeuvres Disponibles/Invader, PA-1060.jpg BD.jpg"
import InvaderPhoto9 from "../../../../res/Photos site/Photos Oeuvres Disponibles/Invaderoma.png"
import CarrouselCartel from "../../../../components/CarrouselCartel";
import Button from "./../../../../components/Button";
import Pdp from '../../../../res/invader/portrait.jpg'
import PastPresent1 from "../../../../res/invader/past-present-1.jpg"
import PastPresent2 from "../../../../res/invader/past-present-2.jpg"
import PastPresent3 from "../../../../res/invader/past-present-3.jpg"
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "Invader",
  name: "Invader",
  description:"Invader est un artiste français né en 1969 qui connaît un rayonnement international grâce à des mosaïques pixelisées qu’il installe dans les villes du monde entier. Son nom d’artiste provient d’un jeu d’arcade de 1978. Résidant à Paris, il est diplômé de l’École des Beaux-Arts de Rouen. Oeuvrant dans l’anonymat et dans les rues, Invader a acquis une notoriété mondiale semblable à celle de l'énigmatique Banksy. Ses oeuvres, devenues des symboles, obsèdent les spectateurs qui s’amusent à les retrouver dans les rues grâce à une application mobile. Alors que le projet : Space Invader voit le jour en 1998, en 2021, Invader annonce sur ses réseaux qu’il installe sa quatre-millième mosaïque. Des expositions personnelles lui sont notamment consacrées au Musée en Herbe à Paris en 2017, et à la HOCA Fondation à Hong Kong en 2015. ",
  pdp: Pdp,
  alt_pdp: "Photo de profil de Invader.",
  photos: [
    { src: PastPresent1, name: "Invader" },
    { src: PastPresent2, name: "Invader" },
    { src: PastPresent3, name: "Invader" },
  ],
  citations: [
    "« Well, When I started my \"Invasions,\" the word \"street art\" did not even exist yet! Now \"Street Art\" is a Pivotal moment »",
    "« I always try to use new media for the gallery, but I realize that pixels and mosaics are mainly my signature. »",
    "« As always, I brought mosaic artworks along with me just in case I found a good location to display one of them along the way. »",

  ]
};

const infos2 = {
  photos: [
    // { src: DanielArsham1, name: "Daniel Arsham", artist: "Daniel Arsham (1980)", title:"Ash Eroded Film Reel, 2013", desc1:"Cendres volcaniques, verre brisé, hydrostone", desc2:"Volcanic ash, shattered glass, hydrostone", desc3:"35 x 35cm", desc4:"14 7⁄8 x 14 7⁄8 in.", desc5:"Sculpture originale, pièce unique", desc6:"Original sculpture, unique work", desc7: "- Collection particulière.\n- Ron Mandos Gallery, Pays-Bas.", desc8:"<h4>-<i> Nos Fantômes - </i>exposition inaugurale, Ghost galerie, Paris, France. 10 Septembre - 20 Novembre, 2021</h4>", desc10:"<h4>- Un certificat de Ron Mandos Gallery sera remis à l'acquéreur.</h4>"  }
    { src: InvaderPhoto7, name: "Invader", artist: "Invader (1969)", title:"Half Phantom, 2003", desc1:"Carreaux de mosaïque sur panneau", desc2:"Mosaic tiles on panel", desc3:"40 x 40 cm", desc4:"15,75 x 15.75 in.", desc5:"Numéro d’inventaire d’Invader : 14995", desc6:"Invader’s inventory n° 14995", desc7: "- Collection particulière, Italie.\n- Magda Danysz Gallery, France.", desc10 :"<h4>- Le certificat d'authenticité de Magda Danysz Gallery sera remis à l'acquéreur\n<i>A certificate of authenticity from Magda Danysz Gallery will be delivered to the buyer</i></h4>" },
    { src: InvaderPhoto8, name: "Invader", artist:"Invader (1969)", title:"ALIAS PA-1060, 2013", desc1: "Carreaux de mosaïque montés sur panneau en plastique", desc2:"Mosaic tiles mounted on plastic panel", desc3:"105 x 165,3 cm", desc4: "41 3/8 x 65 1/8 in.", desc7:"- Collection particulière.\n- Over the Influence, Hong Kong.\n\n- Alias du PA-1060 situé au 2 Villa de Guelma / Boulevard de Clichy, 75018 Paris, France. Illustré sur le site officiel d'Invader.", desc8:"<h4>- <i>Nos Fantômes</i> - exposition inaugurale, Ghost galerie, Paris, France.\n10 Septembre - 20 Novembre, 2021.</h4>",   desc10 :"<h4>- Le certificat d'authenticité de l'artiste sera remis à l'acquéreur</h4>"},
    { src: InvaderPhoto9, name: "Invader", artist:"Invader (1969)", title:"Invaderoma : l'unité centrale (Invasion guide 04), 2010", desc1:"Carreaux de micro-mosaïques sur couverture du livre dans une boite en plexiglass", desc2:"Micro-mosaic tiles on hardcover book in a plexiglass box", desc3:"23,5 x 21 x 2 cm", desc4:"9 1⁄4 x 8 1⁄4 x 0,78 in", desc5:"Edition à 60 exemplaires + 4 AP\nNuméro : AP", desc7:"- Collection particulière." },
  ],
};

const Invader = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
       <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>INVADER <h2 style={{paddingLeft: "16px"}}>(Français)</h2></h1>
          <h3 className={NameWrapper} >Né en 1969</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <br />
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        <ul className={ArtistInfos}>
          <p style={{fontWeight: "bolder"}}>EDUCATION</p>
          <br />
          <p>- École Nationale Supérieure des Beaux-Arts de Paris.</p>
          <p>- École Régionale des Beaux-Arts de Rouen.</p>
          <br />
          <br />
          <p style={{fontWeight: "bolder"}}>SOLO EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2022</p>
          <p>- Invader Rubikcubist, Mima Museum, Malenbeek Saint-Jean, Belgique, 24 juin - 8 janvier 2023.</p>
          <p>- Invader Part 3, galerie Le Feuvre & Roze, Paris, 23 juin - 23 juillet.</p>
          <p>- Open world, Video games & contemporary Art, Oklahoma contemporary, city Okhlaoma, October 21th - February 21th.</p>
          <p>- Winter Contemporary, Maddox Gallery, GSTADD, Switzeland, December 16th - January 23th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Prints on paper, MGLC, Centre international des arts graphiques, Ljubljana, Slovénie, December 22nd, 2020 - August 15th, 2021.</p>
          <p>- Ghost DNA, exposition d’ouverture - opening exhibition, Ghost galerie, Paris, France, July 4th - September 4th.</p>
          <p>- Invader POI, Points of invasions, Over the Influence, Los Angeles, November 20th - December 23th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p>- Invader was here, MaMo, Marseille, France, August 28th - November 11th.</p>
          <p>- Hanging, Over the influence, Hong Kong, July 10th - August 9th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- Into the white cube, Over the Influence, Los Angeles, USA, November 17th - December 23rd.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2017</p>
          <p>- Hello my game is, Le Musée en Herbe, Paris, France, Janvier 26th - September 3rd.</p>
          <p>- Invader: New Mosaics of Ravenna, Over the Influence, Hong Kong, October 15th - October 26th.</p>
          <div className={More} id="more">
          <br />
          <p style={{fontWeight: "bold"}}>2015</p>
          <p>- Wipe Out, HOCA Foundation, PMQ, Hong Kong, May 2nd - May 17th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2012</p>
          <p>- Dérives, Alice Gallery, Brussels, Belgium, April 20th - May 19th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- Attack of the Space Waffles, Lazarides Gallery, London, United Kingdom, December 1st - 24th.</p>
          <p>- 1000, La Générale et Franck Le Feuvre Gallery, Paris, France, November 17th - December 17th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2010</p>
          <p>- Home Sweet Home, Gallery Target, Tokyo, Japan, October 29th - November 19th.</p>
          <p>- Roma 2010 and Other Curiosities, Wunderkammern, Roma, Italy, October 23rd - December 21st.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- Low Fidelity, Lazarides Gallery, London, United Kingdom, August 14th - September 17th.</p>
          <p>- TOP 10, Jonathan Levine Gallery, New York, USA, June 27th - July 25th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2008</p>
          <p>- Béton Blumen - Invaded Bridge, Museumsquartier, Vienna, Austria, September 12th - November 7th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2007</p>
          <p>- Bad Men Part II and Invasion London, Lazarides Gallery, London, United Kingdom.</p>
          <p>- Bad Men-Part I, Galerie Joelle Beck, Cologne, Germany.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2005</p>
          <p>- Compilation, Galerie Bellecour, Lyon, France, September.</p>
          <p>- Rubikcubism, Six Space, Los Angeles, USA, May.</p>
          <p>- Rubik Space, Galerie Patricia Dorfmann, Paris, France, April 24th - May 7th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2004</p>
          <p>- I Invade HOLLYWOOD, Subliminal Projects, Los Angeles, USA, April.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2003</p>
          <p>- Game not over, Galerie Magda Danysz, Paris, France, May..</p>
          <p>- Invader, Citylights Gallery, Melbourne, Australia, February.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2001</p>
          <p>- I Invade Tokyo, And-a, Osaka, Japan, November.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2000</p>
          <p>- Same player shoot again, Galerie Almine Rech, Paris, France, May.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1999</p>
          <p>- New player insert coins, Jean Charles de Castelbajac CS, Paris, France, May.</p>
          <br />
          <p style={{fontWeight: "bolder"}}>GROUP EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2022</p>
          <p>- Blue Chip Artists, Patrick Jones Gallery</p>
          <p>- Mickys art presents: Internationally renowned street and contemporary artists from</p>
          <p>around the globe, Mickys art</p>
          <p>- Worldwide Contemporary Art, 3 White Dots</p>
          <p>- Gorky to KAWS (1931-2018), VINCE fine arts/ephemera</p>
          <p>- ARTephemera (1930-present), VINCE fine arts/ephemera</p>
          <p>- New York Street Style : création des années 80 - Ghost Galerie, Paris, juillet -</p>
          <p>septembre 2022.</p>
          <p>- Accrochage saisonnier, Ghost Galerie, Paris, France, Janvier-Juin 2022 -</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Nos Fantômes - exposition inaugurale, Ghost galerie, Paris, France, 10 Septembre - 20 Novembre, 2021.</p>
          <p>- Ghost DNA, exposition d’ouverture - opening exhibition, Ghost galerie, Paris, France, July 4th - Septembera 4th.</p>
          <p>- The Holiday Collection, End to End Gallery</p>
          <p>- Christmas Gift Guide, Hidden</p>
          <p>- Art Miami 2021, HOFA Gallery (House of Fine Art)</p>
          <p>- STREET ART SUPERSTARS, Silverback Gallery</p>
          <p>- Street Art & Beats, Silverback Gallery</p>
          <p>- Side X Side Summer Selection: Power of Flowers, Side X Side Gallery</p>
          <p>- Editions and Multiples, Lougher Contemporary</p>
          <p>- Masters of Urban Contemporary Art III, End to End Gallery</p>
          <p>- Flux Factory Fundraising Exhibition, Flux Factory</p>
          <p>- (RE)COLLECTIONS, CHENUS LONGHI</p>
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p>- Open World : Video Games and Contemporary Art, Akron Museum of Art, Akron, OH, October 19th - 2019, February 2nd, 2020.</p>
          <p>- No Tricks, Just Treats, End to End Gallery</p>
          <p>- ICONS, Lyons Gallery</p>
          <p>- Interiors - A Study Within Walls, Marcel Katz Art</p>
          <p>- Treasures, Danysz Gallery</p>
          <p>- Futurama, Tram Collective</p>
          <p>- GONE IN A FLASH, Art Republic</p>
          <p>- In Bloom, Art Republic</p>
          <p>- Mehdi Cibille — Invader — Sowat — Franck Noto aka Zest, Urvanity</p>
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p>- Beyond the streets NYC 2019, 25 Kent Ave, New York, USA, June 21st - September 29th.</p>
          <p>- The Art Plug's August Picks, Marcel Katz Art</p>
          <p>- Street Art Collection, Art Republic</p>
          <p>- Editions and Multiples, Lougher Contemporary</p>
          <p>- Contemporary Editions, Lougher Contemporary</p>
          <p>- CROSSOVER II, Galerie Kronsbein</p>
          <p>- 5ART: Street Art & Contemporary Editions, 5ART GALLERY</p>
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- Beyond the streets NYC 2018, Werkartz, Los Angeles, USA, May 6th - August 26th.</p>
          <p>- La scultura in mosaico dalle origini ad oggi, MAR, Ravenna, Italy, October 7th, 2017 - Janvier 7th, 2018.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2017</p>
          <p>- Unstoppable, Urban Nation Museum, Berlin, Germany, September 16th - 17th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2016</p>
          <p>- Still Here, A Decade of Lazarides, Lazarides Gallery, London, United Kingdom, February 12th - March 24th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2014</p>
          <p>- Asphalte #1, BPS.22, Charleroi, Belgium, June 7th - October 26th.</p>
          <p>- The Provocateurs, Block 37, Art Alliance, Chicago, USA, July 31st - August 4th.</p>
          <p>- O(Ax) = dO(Am) Equazione Impossibile, MAR, Ravenna, Italy, December 10th, 2013 - January 6th, 2014.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2013</p>
          <p>- Art4Space, Nuit Blanche de Metz, Metz, France, October 5th - 6th.</p>
          <p>- Von Picasso bis Ai Weiwei - Die Wiederentdeckung der Keramik in der Kunst, Fondation Gerich, Neumunster, Germany, May.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2012</p>
          <p>- Street Art, Kunsthallen Brandts, Odense, Denmark, September 7th - November 25th.</p>
          <p>- Vues Sur Mur, Centre de la gravure et de l’Image Imprimée, Bruxelles, Belgium, May 26th - September 2nd.</p>
          <p>- Street Art : The New Generation, Pori Art Museum, Pori, Finland, February 10th - May 27th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- De dentro e de fora, MASP, Sao Paulo, Brazil, August 17th - December 23rd.</p>
          <p>- Art in the Streets, MOCA, Los Angeles, USA, April 17th - August 8th.</p>
          <p>- Viva La Revolucion, Museum of Contemporary Art of San Diego, San Diego, USA, July 18th, 2010 - Janvier 2nd, 2011.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2010</p>
          <p>- Urban Alchemists, Jonathan LeVine Gallery, Miami, USA, January 12th - May 12th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- Grifters, Lazarides gallery, London, United Kingdom, November 17th - December 15th.</p>
          <p>- Hitchhiker’s to the Galaxy, Daejeon Museum of Arts, Daejeon, South Korea, September 30th - November 25th.</p>
          <p>- État des lieux, Galerie du Jour, Paris, France, September 4th - October 10th.</p>
          <p>- Ingres et les Modernes, Musée Ingres et Musée du Louvre, Montauban, France, July 3rd - October 4th.</p>
          <p>- V.01 B, Lieu Commun, Toulouse, France, April 3rd - 25th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2008</p>
          <p>- Outsiders NY, Off site exhibition by Lazarides, New York, USA, September 26th - October 12th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2007</p>
          <p>- On fait le mur, Espace de l’Art Concret, Mouans Sartoux, France, July 2nd, 2007 - January 1st, 2008.</p>
          <p>- Streets of Europe, Jonathan Levine Gallery, New York, USA, November 12th - December 3rd.</p>
          <p>- Bad Men Part II, Lazarides Gallery, London, United Kingdom, October 5th - November 9th.</p>
          <p>- Small, Medium and Large, ELMS Lesters Painting Room, London, United Kingdom, August 22nd - September 21st.</p>
          <p>- Spank the monkey, BALTIC Center for Contemporary Art, Newcastle, United Kingdom, September 26th, 2006 - January 7th, 2007.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2006</p>
          <p>- Hype, ELMS Lesters Painting Room, London, United Kingdom, June 4th - July 15th.</p>
          <p>- Street Art MGLC, Ljubljana, Slovenia.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2005</p>
          <p>- New traditionalists, Subliminal projects, Los Angeles, USA, July 9th - September.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2003</p>
          <p>- Copy it, steal it, share it, Borusan Center for Culture and Arts/Istanbul, Turkey, September.</p>
          <p>- While you were playing Rubik’s cube, Flux Fac, New York, USA, July.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2002</p>
          <p>- Mono culture, Bread Box, Gallery, Perth, Australia, September.</p>
          <p>- Street art, Mjelby Konstgard Museum, Halmstad, Sweden, September.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2001</p>
          <p>- Graphic Life, Double park, Hong Kong, September.</p>
          <p>- Biennale d’art contemporain, Musée d’art contemporain, Lyon, France, June 23rd - September 23rd.</p>
          <p>- Expressure 4.2, MAMA, Rotterdam, The Netherlands, December.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2000</p>
          <p>- Version 2000, Centre pour l’Image Contemporaine, Geneva, Switzerland, November 3rd - December 17th.</p>
          <p>- Invasion d’Avignon, La Beauté, Avignon, France, July.</p>
          <p>- Ideal room, Galerie Patricia Dorfmann, Paris, France, June.</p>
          <br />
          <br />
          <p style={{fontWeight: "bolder"}}>BIBLIOGRAPHY</p>
          <br />
          <p>- Invader, L’invasion de Paris, Guide d’invasion 01, Paris / Episode 01 : La genèse, F.</p>
          <p>- Slama, décembre 2003, 1er ed., broché (ISBN 978-2-9520199-2-7)</p>
          <p>- Invader (interview Jean Marc Avrilla, trad. anglaise Chris Burke), Invasion Los Angeles, Guide d'invasion 02, F. Slama, décembre 2004, broché, (ISBN 2-9520199-4-0).</p>
          <p>- Invader (préface Jean Marc Avrilla), Rubik space : a picture book, F. Slama, mars 2005, 64 p., broché, (ISBN 2-9520199-5-9).</p>
          <p>- Invader, Invasion in the U.K., London, Manchester, Newcastle, Guide d'invasion 03, F. Slama, décembre 2007, 192 p., broché, (ISBN 978-2-9520199-7-2).</p>
          <p>- Invader, Invaderoma, Guide d'invasion 04, 2010</p>
          <p>- Invader, L'invasion de Paris, Guide d'invasion 01, Paris / Livre 01 : La genèse, Unité Centrale, février 2009, 3e éd (1re éd 2005), 224 p., broché, (ISBN 978-2-9520199-3-4)</p>
          <p>- Invader, Mission Miami, Guide d'invasion 05, 2012</p>
          <p>- Invader, Wipe Out in Hong Kong, Guide d'invasion 06, 2015</p>
          <p>- Invader, New Mosaics of Ravenna, Guide d'invasion 07, 2017</p>
          <p>- Invader (préface et traduction Bruno Blum), 2.1 Invasion Los Angeles updated edition / 1999-2018, Guide d'invasion 01, Paris / Livre 01 : La genèse, Control P Editions, octobre 2018, 2e éd (1re éd 2004), 319 p., (ISBN 978-2-9541259-4-7)</p>
          <p>- Invader, Prints on paper, Catalogue raisonné 2001-2020, Control P Editions, février 2021.</p>
          <br />
          <br />
          <p style={{fontWeight: "bolder"}}>EXHIBITION CATALOGS</p>
          <br />
          <p>- 1000 7 juin - 3 juillet, 2011, Galerie Le Feuvre, 51 p. (ISBN 978-2-918330-11-0).</p>
          <p>- Low Fidelity, Londres, Lazarides gallery, 2009 (ISBN 978-0-9560007-2-9).</p>
          <p>- TOP 10, New York, Jonathan Levine Gallery, 2009 (ISBN 978-0-9729387-2-3).</p>
          <p>- Ingres et les modernes, Paris, Sogedif, 2009.</p>
          <p>- London Invasion / Bad Men Part II, Londres, Galerie Lazarides, 2007 (ISBN 978-0-9554178-9-4).</p>
          <p>- Spank the Monkey, Cologne, BALTIC, Galerie Joelle Beck, 2007 (ISBN 978-3-0002142-0-2).</p>
          <p>- Rubik Space, Paris, Franck Slama, avril 2005 (ISBN 2-9520199-5-9).</p>
          <p>- Catalogue de son exposition personnelle à la galerie Patricia Dorfmann.</p>
          <p>- Copy it, Steal it, Share it, Istanbul, Borusan Culture and Art Center, 2003.</p>
          <p>- Mono Culture, Perth, Bread Box Gallery, 2002.</p>
          <p>- Connivence, 6e biennale d'art contemporain de Lyon, Paris, RMN Réunion des musées nationaux, 2001 (ISBN 2-711842592).</p>
          <br />
          <br />
          <p style={{fontWeight: "bolder"}}>CARTOGRAPHY</p>
          <br />
          <p>- Invasion of Paris, éditée par Invader et la galerie Le Feuvre, juillet 1999.</p>
          <p>- Attack of Montpellier, 1999.</p>
          <p>- Invasion de Grenoble, éditée par l'École des beaux-arts de Grenoble, décembre 1999.</p>
          <p>- Bern to be Invade, in Soda Magazine, n°14, 2000.</p>
          <p>- Invasion of Avignon, éditée par Global Tekno 200, juillet 2000.</p>
          <p>- L’invasion de Genève, éditée par le Centre pour l'image contemporaine, novembre 2000.</p>
          <p>- Invasion de Lyon, éditée par la Biennale d'art contemporain de Lyon 2001, juin 2001.</p>
          <p>- Invasion of Rotterdam, éditée par MAMA, Rotterdam, décembre 2001.</p>
          <p>- Invasion of Tokyo, in Relax Magazine, n°61, mars 2002.</p>
          <p>- Invasion of Perth, éditée par Bread Box Gallery, octobre 2002.</p>
          <p>- Invasion of New York City, éditée par Flux Factory, juin 2003.</p>
          <p>- L’invasion de Los Angeles, éditée par Subliminal Projects, avril 2004.</p>
          <p>- United Invasion of Manchester, éditée par Peer Group Project, juillet 2004.</p>
          <p>- L’invasion de Bastia, éditée par Arte Mare, septembre 2005.</p>
          <p>- Invasion Von Wien, éditée par Subotron & MQ 21, juillet 2006.</p>
          <p>- Invasion côte d’Azur, éditée par l'Espace de l'art concret, 2007.</p>
          <p>- Invasion de Bilbao, éditée par Bilbao Arte Fundacion, janvier 2008.</p>
          <p>- Invasion of Kathmandu, éditée par Invader, août 2008.</p>
          <p>- Invasione di Roma, éditée par Wunderkammern, septembre 2010.</p>
          <p>- Invasion de Paris (2), éditée par la galerie Le Feuvre, juin 2011.</p>
          <p>- Invasao Sao Paulo, éditée par MASP, 2011.</p>
          <p>- Bxl 2012 Derives, 2012.</p>
          <p>- Invasion of Miami, éditée par Jonathan Levine Gallery, décembre 2012.</p>
          <p>- Djerba island, 2020.</p>
          <p id="end">- Invasion planète Marseille, 2020.</p>
          </div>
        </ul>
            <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
        </div>
      </div>
      

      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/fr/contactez-nous";
                }
              }
            }
            >
              Contactez-nous
            </Button>
        </div>
    </div>
  );
};

export default Invader;