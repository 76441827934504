// extracted by mini-css-extract-plugin
export var ArtistDescription = "Invader-module--ArtistDescription--Ramua";
export var ArtistInfos = "Invader-module--ArtistInfos--hjvhl";
export var ButtonWrapper = "Invader-module--ButtonWrapper --dlT5c";
export var CardWrapper = "Invader-module--CardWrapper--Zscm1";
export var CarrouselWrapper2 = "Invader-module--CarrouselWrapper2--XaenL";
export var Citations = "Invader-module--Citations--paGOp";
export var DescriptionWrapper = "Invader-module--DescriptionWrapper--F35W5";
export var ImageWrapper = "Invader-module--ImageWrapper--hEYOO";
export var LinkWrapper = "Invader-module--LinkWrapper--NIjSs";
export var MobileProtrait = "Invader-module--MobileProtrait--XTyFG";
export var More = "Invader-module--More--Guio3";
export var MoreButton = "Invader-module--MoreButton--LKQFN";
export var NameWrapper = "Invader-module--NameWrapper--Mb5BS";
export var PdpWrapper = "Invader-module--PdpWrapper--kcEQP";
export var PhotosWrapper = "Invader-module--PhotosWrapper--1pUaK";
export var ProfilWrapper = "Invader-module--ProfilWrapper--0mbxF";
export var TitleWrapper = "Invader-module--TitleWrapper--Se22Y";
export var Wrapper = "Invader-module--Wrapper--IwyIy";